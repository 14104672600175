// List of all cards used in the application

const CardsList = [
    { id: 'userInfo', name: 'User Information', image: '/CardImages/UserInfo.png', size: 'small'},
    { id: 'aircraftRequirements', name: 'Aircraft Requirements', image: '/CardImages/AircraftReqs.png', size: 'medium'},
    { id: 'flights', name: 'Flights', image: '/CardImages/FlightInfo.png', size: 'large'},
    { id: 'weather', name: 'Weather', image: '/CardImages/Weather.png', size: 'medium'},
    { id: 'chart', name:'Chart', image: '/CardImages/Chart.png', size: 'medium'},
    { id: 'fuelBurn', name:"Fuel Burn", image:'/CardImages/FuelBurn.png', size: 'medium'}
    // Add more cards as needed
  ];

  export default CardsList;